$container-padding-x-sm: 0.75rem;
$container-padding-x-md: 1.5rem;
$container-padding-x: 3.75rem;

@mixin make-container($padding-x: $container-padding-x-sm) {
  width: 100%;
  padding-right: $container-padding-x-sm;
  padding-left: $container-padding-x-sm;
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
}

// Usage
.container {
  @include make-container();


  @include media-breakpoint-up(tablet) {
    padding-right: $container-padding-x-md;
    padding-left: $container-padding-x-md;
  }

  @include media-breakpoint-up(desktop) {
    padding-right: 70px;
    padding-left: 70px;
  }

  @include media-breakpoint-up(desktop-lg) {
    padding-right: $container-padding-x;
    padding-left: $container-padding-x;
  }

  & + & {
    margin-top: 70px;
  }
}

.container-fluid {
  max-width: 1440px;
}

.container-md {
  @include make-container();

  @include media-breakpoint-up(tablet) {
    max-width: 100%;
    padding: 0 126px;

    .header & {
      padding-right: $container-padding-x-md;
      padding-left: $container-padding-x-md;
    }
  }

  @include media-breakpoint-up(tablet-lg) {
    max-width: 752px;
    padding: 0;

    .header & {
      padding-right: $container-padding-x-md;
      padding-left: $container-padding-x-md;
      max-width: 100%;
    }

  }

  @include media-breakpoint-up(desktop) {
    max-width: 872px;

    .header & {
      max-width: 872px;
      padding: 0;
    }
  }

  @include media-breakpoint-up(wide) {
    max-width: 872px;
  }


  & + &,
  .container + &,
  & + .container {
    margin-top: 40px;

    @include media-breakpoint-up(tablet) {
      margin-top: 70px;
    }
  }
}
