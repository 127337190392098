//---------------------------------------------
//
//  Slider Component
//
//    1. Imports
//    2. Component
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------

@import "../variables";

//---------------------------------------------
// 2. Component
//---------------------------------------------

// Nothing here yet

.swiper {
  &-container {
    display: flex;
    flex-direction: column;
  }

  &-slide {
    height: initial;
  }

  &-buttons {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 0 !important;
  }

  &-button {
    background-color: transparent;
    border: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    &__icon {
      width: 100%;
      height: auto;
    }

    &-prev {
      transform: scale(-1);
      margin-right: 20px;
    }
  }
}
