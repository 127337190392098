.feedback {
  overflow: hidden;

  .swiper-slide {
    height: initial;
  }
}

.feedback__wrapper {
  margin: 0 -80%;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(tablet) {
    margin: 0 -35%;
  }

  @include media-breakpoint-up(tablet-lg) {
    margin: 0 -30%;
  }

  @include media-breakpoint-up(desktop) {
    margin: 0 -10%;
  }
}

.feedback__card {
  display: flex;
  flex-direction: column;

  padding: 16px;
  min-height: 100%;
  border-radius: 16px;
  border: 1px solid #808080;


  @include media-breakpoint-up(tablet) {
    padding: 30px;
  }
}

.feedback__person {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  @include media-breakpoint-up(tablet) {
    margin-bottom: 20px;
  }

  span {
    font-size: 16px;
    font-weight: 400;
    color: #9D9D9D;
    line-height: 22px;
  }
}

.feedback__text {
  flex: 1 0 auto;
  margin-bottom: 20px;
  line-height: 24px;

  @include media-breakpoint-up(tablet) {
    line-height: 28px;
    margin-bottom: 24px;
  }
}

.feedback__card-link {

  &::before {
    display: none;
  }

  img {
    margin-left: 8px;
    width: 20px;
    height: 20px;
  }
}

.feedback-swiper {
  margin-bottom: 50px;
  overflow: hidden;

  @include media-breakpoint-up(tablet) {
    margin-bottom: 50px;
  }
}

.feedback__nav {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 0 !important;
}

.feedback__link {
  position: relative;

  @include media-breakpoint-up(tablet) {
    display: inline-block;
  }
}

.feedback__swiper-buttons {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.feedback__swiper-button {
  background-color: transparent;
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;

  img {
    width: 100%;
    height: auto;
  }

  &.swiper-button-prev {
    transform: scale(-1);
    margin-right: 20px;
  }
}
