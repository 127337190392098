.pagination {
  margin-top: 20px;

  @include media-breakpoint-up(phone-l) {
    margin-top: 60px;
  }

  @include media-breakpoint-up(tablet) {
    margin-top: 40px;
  }

  @include media-breakpoint-up(tablet-lg) {
    margin-top: 60px;
  }
}

.pagination__list {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.pagination__item {
  width: 32px;
  height: 32px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    margin-right: 4px;
  }

  &--current {
    background-color: $red;
  }
}

.pagination__item-link {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 16px;
}

.pagination__item-control {
  width: 22px;
  height: 22px;

  &:first-child {
    margin-right: 16px;
  }

  &:last-child {
    margin-left: 16px;
    transform: scale(-1);
  }
}
