.cases__tags {
  display: flex;
  flex-wrap: wrap;

  &_item {
    margin-bottom: 16px;
    position: relative;

    input {
      top: 0;
      left: 0;
      position: absolute;
      opacity: 0;
      width: .5px;
      height: .5px;
      &:checked + .btn {
        --bs-btn-color: #ffffff;
        --bs-btn-bg: #BD1622;
        --bs-btn-border-color: #BD1622;
        --bs-btn-hover-color: #ffffff;
        --bs-btn-hover-bg: #a1131d;
        --bs-btn-hover-border-color: #97121b;
        --bs-btn-focus-shadow-rgb: 199, 57, 67;
        --bs-btn-active-color: #ffffff;
        --bs-btn-active-bg: #97121b;
        --bs-btn-active-border-color: #8e111a;
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: #ffffff;
        --bs-btn-disabled-bg: #BD1622;
        --bs-btn-disabled-border-color: #BD1622;
      }
    }

    &:not(:last-child) {
      margin-right: 16px;
    }

    @include media-breakpoint-up(tablet) {
      margin-bottom: 0;
    }
  }
}

.cases__filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cases__range {
  display: none;
  align-items: center;

  .form-label {
    margin-right: 14px;
    margin-bottom: 0;
  }

  input {
    transform: scale(-1);
  }

  @include media-breakpoint-up(tablet) {
    display: flex;
  }
}

.cases__list {
  padding: 24px 0 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @include media-breakpoint-up(tablet) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(tablet-lg) {
    padding: 44px 0 0;
  }
}

.columns {
  &-2 {
    grid-template-columns: repeat(1, 1fr);

    @include media-breakpoint-up(tablet) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &-3 {
    grid-template-columns: repeat(1, 1fr);

    @include media-breakpoint-up(tablet) {
      grid-template-columns: repeat(3, 1fr);
    }


    .case-card__name {
      font-size: 22px;
      line-height: 32px;
      left: 20px;
      bottom: 20px;

      @include media-breakpoint-up(tablet-lg) {
        font-size: 24px;
        line-height: 34px;
      }
    }

    .case-card__top {
      left: 20px;
      top: 20px;
    }

    .hash {
      font-size: 16px;
      line-height: 22px;
    }
  }

  &-4 {
    grid-template-columns: repeat(1, 1fr);

    @include media-breakpoint-up(tablet) {
      grid-template-columns: repeat(4, 1fr);
    }

    .case-card__name {
      font-size: 18px;
      line-height: 28px;
      left: 12px;
      bottom: 12px;

      @include media-breakpoint-up(tablet-lg) {
        font-size: 20px;
        line-height: 28px;
        left: 20px;
        bottom: 20px;
      }
    }

    .case-card__top {
      left: 12px;
      top: 12px;

      @include media-breakpoint-up(tablet-lg) {
        left: 20px;
        top: 20px;
      }
    }

    .hash {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
