//---------------------------------------------
//
//  Variables
//
//    1. Bootstrap Fonts
//    2. Bootstrap Globals
//    3. Bootstrap Colors
//    4. Bootstrap Container Width
//
//---------------------------------------------

//---------------------------------------------
// 1. Bootstrap Fonts
//---------------------------------------------

// $font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-family-base: 'Manrope', sans-serif;
$font-family-second: 'Montserrat', sans-serif;

//---------------------------------------------
// 2. Bootstrap Globals
//---------------------------------------------

$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;

//---------------------------------------------
// 3. Bootstrap Colors
//---------------------------------------------

// Custom Colors

$white:      #ffffff;
$white-20:     rgba($color: $white, $alpha: 0.02);
$white-50:     rgba($color: $white, $alpha: 0.5);
$black-40:   #3a3a3a;
$blue:       #0E37C8;
$red:        #BD1622;
$red2:       #BE424B;
$yellow:     #DCFF02;

$body-color: #000000; // The default text color

$primary:    $white;
$secondary:  $red;
$success:    #198754;
$info:       #0dcaf0;
$warning:    #ffc107;
$danger:     #dc3545;
$light:      $white-20;
$dark:       #212529;

//---------------------------------------------
// 4. Bootstrap Container Width
//---------------------------------------------

$container-max-widths: (
  sm: 414px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1440px
);

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: ($spacer * 0.25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  6: ($spacer * 5),
  7: ($spacer * 7),
  8: ($spacer * 8)
);

$grid-gutter-width: 1.5rem;

$form-range-track-bg: $white;
$form-range-track-height: calc(1rem / 16);

// Bootstrap breakpoints

$grid-breakpoints: (
  xs: 0,
  sm: 415px,
  md: 769px,
  lg: 1025px,
  xl: 1281px,
  xxl: 1441px
);
