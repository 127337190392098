.callback {
  position: relative;
  padding-bottom: 20px;

  @include media-breakpoint-up(tablet) {
    padding: 60px 0;
  }
}

.callback__image-wrapper {
  position: absolute;
  top: 80px;
  left: 67px;
  padding: 0;
  transform: translateX(-50%);

  @include media-breakpoint-up(tablet) {
    position: relative;
    bottom: auto;
    left: auto;
    top: auto;
    transform: none;
    padding-left: 0;
  }

  @include media-breakpoint-up(tablet-lg) {
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  picture {
    display: block;
    width: 100%;
    height: auto;
    margin-left: calc(var(--bs-gutter-x) * -0.5);

    @include media-breakpoint-up(tablet-lg) {
      margin-left: 0;
    }
  }
}

.callback__content {
  position: relative;
  z-index: 1;

  @include media-breakpoint-up(tablet) {
    padding-left: 0;
  }

  @include media-breakpoint-up(tablet-lg) {
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}

.callback__title {
  padding-bottom: 330px;
  margin-bottom: 0;

  @include media-breakpoint-up(tablet) {
    padding-bottom: 0;
    margin-bottom: 60px;
  }

  @include media-breakpoint-up(tablet-lg) {
    padding-bottom: 0;
    margin-bottom: 80px;
  }
}
