.cases__title-holder {
  display: flex;
  flex-direction: column;
}

.cases__title {
  font-weight: 800;
  &:not(:last-child) {
    margin-bottom: 4px;
  }

  &:nth-child(2) {
    padding-left: 16%;

    @include media-breakpoint-up(tablet) {
      padding-left: 32%;
    }
  }

  &:nth-child(3) {
    padding-left: 10%;

    @include media-breakpoint-up(tablet) {
      padding-left: 15%;
    }
  }
}

.case-card {
  position: relative;
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1)) !important;
  display: block;
  overflow: hidden;
  padding-top: calc(100% / (4 / 3));
  transition: color .3s ease;
  &:before {
    content: '';
    top: 0;
    right: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 28.5%, rgba(0, 0, 0, 0) 77.06%, rgba(0, 0, 0, 0.4) 93.7%);
  }
}

.case-card__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.case-card__top {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 2;
  display: flex;
  align-items: center;
}

.hash {
  text-decoration: none;
  font-size: 16px;


  @include media-breakpoint-up(tablet) {
    font-size: 18px;
  }

  @include media-breakpoint-up(desktop) {
    font-size: 20px;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.case-card__name {
  text-decoration: none;
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 2;
  font-size: 24px;
  font-weight: 600;

  @include media-breakpoint-up(tablet) {
    font-size: 26px;
  }

  @include media-breakpoint-up(tablet-lg) {
    font-size: 30px;
  }

  @include media-breakpoint-up(desktop) {
    font-size: 40px;
  }
}
