.banner {
  position: relative;
  overflow: hidden;
  padding-top: 200px;

  @include media-breakpoint-up(tablet) {
    padding-top: 0;
  }
}

.banner__content {
  display: flex;
  flex-direction: column;
  margin: 0;
  position: relative;
  z-index: 1;
  padding-bottom: 220px;

  @include media-breakpoint-up(tablet) {
    padding-bottom: 0;
  }

  @include media-breakpoint-up(desktop) {
    margin: 15.6rem 0;
  }
}

.banner__title {
  &:first-child {
    color: $red;
  }

  &:nth-child(3) {
    padding-left: 30%;

    @include media-breakpoint-up(tablet) {
      padding-left: 23%;
    }
  }

  &:nth-child(4) {
    padding-left: 7%;
  }
}

.banner__image-col {
  position: absolute;
  bottom: 0;
  left: 0;

  @include media-breakpoint-up(tablet) {
    position: relative;
    top: unset;
    left: unset;
  }
}

.banner__image-wrapper {
  width: 100%;
  height: 320px;
  position: relative;
  margin-left: 0;

  transition: all 0.3s;

  &.dom-loading--init {
    &:before {
      content: '';

      position: absolute;
      top: 0;
      left: 20%;
      width: 100%;
      height: 100%;

      background: url('../images/banner/banner.png') no-repeat center center;
      background-size: contain;

      @include media-breakpoint-up(tablet-lg) {
        left: 0;
      }
    }
  }

  // animation-direction: alternate;

  .banner__image {
    position: absolute;
    top: 0;
    left: 20%;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include media-breakpoint-up(tablet-lg) {
      left: 0;
    }
  }

  @include media-breakpoint-up(tablet) {
    height: 706px;
    margin-top: 65px;
  }

  @include media-breakpoint-up(desktop) {
    height: 100%;
    margin-right: -2rem;
    margin-left: -2rem;
    margin-top: 0;

    animation-name: zoom;
    animation-duration: 10s;
    animation-iteration-count: infinite;
  }
}


@keyframes zoom {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.02);
  }

  50% {
    transform: scale(1.04);
  }

  75% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}
