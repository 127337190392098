.form {
  width: 100%;
  display: block;
}

.form-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  --bs-border-opacity: 0.5;
  border-radius: 18px;
  backdrop-filter: blur(3px);

  &--error {
    @extend %form-wrapper--error;
  }

  &--success {
    @extend %form-wrapper--success;
  }

  input {
    font-size: 1rem;
    font-weight: 500;
    border: none;

    &:focus,
    &:focus-visible {
      border: none !important;
      outline: none !important;
    }
  }

  button {
    font-weight: 500;
  }

  p {
    position: absolute;
    left: 0;
    bottom: -30px;
    margin: 0;
    font-size: 1rem;
    display: none;
  };
}

.form__text {
  %form-wrapper--error &,
  %form-wrapper--success & {
    display: inline-block;
  }

  %form-wrapper--error & {
    color: $red;
  }
}
