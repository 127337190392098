.card {
  text-decoration: none;
  border-radius: 16px;
  border: 1px solid $white-50;
  padding: 12px;
  display: flex;
  flex-direction: column;
  min-height: 134px;

  @include media-breakpoint-up(tablet) {
    min-height: 180px;
    padding: 20px;
  }

  @include media-breakpoint-up(tablet-lg) {
    padding: 24px;
  }

  @include media-breakpoint-up(desktop) {
    padding: 30px;
  }

  &--l {
    min-height: 134px;

    @include media-breakpoint-up(tablet) {
      min-height: 258px;
    }
  }
}

.card__cases {
  margin-top: auto;
}
