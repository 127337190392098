.footer {
  margin-top: 40px;
  margin-bottom: 40px;

  @include media-breakpoint-up(tablet-lg) {
    margin-top: 60px;
    margin-bottom: 80px;
  }
}

.footer__contacts {
  list-style: none;
  padding: 0;

  @include media-breakpoint-up(tablet-lg) {
    margin-bottom: 40px;
  }

  li {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  a {
    font-size: 18px;
    line-height: 1em;
  }
}

.font-secondary {
  font-family: $font-family-second;
}
