//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "../../node_modules/swiper/swiper.scss";
@import "fonts";
@import "mixins/project-media";

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

body {
  color: $white;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  &.body--stuck {
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      z-index: 101;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.5);
      backdrop-filter: blur(5px);
    }

  }
}

.hidden {
  display: none;
}

//---------------------------------------------
// 3.Components
//---------------------------------------------

@import 'node_modules/vanilla-infinite-marquee/infinite-marquee.scss';
@import "components/index.scss";
