.ticker {
  overflow: hidden;
  padding: 0;
}

.ticker__image-wrapper {
  margin-right: 28px;
}

.ticker__image {
  min-width: 162px;
  min-height: 20px;

}

.ticker__row {
  overflow: hidden;
  display: flex;
  align-items: center;
}

.horizontal-marquee.smooth {
  mask: unset;
}
