$mediaQueriesUp: (
  phone: 'screen',
  phone-l: 'screen and (min-width: 415px)',
  tablet: 'screen and (min-width: 767px)',
  tablet-lg: 'screen and (min-width: 1023px)',
  desktop: 'screen and (min-width: 1279px)',
  desktop-lg: 'screen and (min-width: 1280px)',
  wide: 'screen and (min-width: 1440px)',
);

$mediaQueriesDown: (
  phone: 'screen and (max-width: 767px)',
  tablet: 'screen and (max-width: 1024px)',
  desktop: 'screen and (max-width: 1319px)',
);

@mixin media-breakpoint-up($device: desktop) {
  @media #{map-get($mediaQueriesUp, $device)} {
    @content;
  }
}

@mixin media-breakpoint-down($device: desktop) {
  @media #{map-get($mediaQueriesDown, $device)} {
    @content;
  }
}
