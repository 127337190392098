.title {
  font-family: $font-family-second;
  font-size: 100px;
  font-weight: 700;
  line-height: 1em;
  color: $white;
}

.subtitle {
  font-size: 36px;
  line-height: 1em;

  @include media-breakpoint-up(phone-l) {
    font-size: 40px;
  }

  @include media-breakpoint-up(tablet) {
    font-size: 48px;
  }

  @include media-breakpoint-up(tablet-lg) {
    font-size: 60px;
  }

  @include media-breakpoint-up(desktop) {
    font-size: 70px;
  }

  &--m {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.3em;

    @include media-breakpoint-up(tablet) {
      font-size: 26px;
    }

    @include media-breakpoint-up(tablet-lg) {
      font-size: 30px;
    }

    @include media-breakpoint-up(desktop) {
      font-size: 40px;
    }
  }
}
