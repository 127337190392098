.breadcrumbs {
  color: $white;
  font-size: 12px;
  margin-bottom: 20px;
  &__row {
    gap: 10px 15px;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    max-width: 100%;
    display: inline-block;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    &:not(:last-of-type) {
      display: inline-flex;
      align-items: center;
      &:after {
        content: '';
        display: block;
        width: 6px;
        min-width: 6px;
        height: 12px;
        margin-left: 15px;
        background: url('../images/icons/arrow-simple.svg') no-repeat center center;
        background-size: cover;
      }
    }
  }

  &__link {
    color: inherit !important;
    font-size: inherit !important;
    text-decoration: none !important;
    line-height: inherit !important;
    margin: 0 !important;
  }
}
