.inner {

  h6 {
    margin-bottom: 24px;
  }

  .section {
    &:first-child {
      padding-top: 40px;

      @include media-breakpoint-up(tablet) {
        padding-top: 80px;
      }

      @include media-breakpoint-up(tablet-lg) {
        padding-top: 60px;
      }

      @include media-breakpoint-up(desktop) {
        padding-top: 80px;
      }
    }
  }
}

.hashs {
  margin-bottom: 30px;

  @include media-breakpoint-up(tablet) {
    margin-bottom: 40px;
  }

  @include media-breakpoint-up(tablet-lg) {
    margin-bottom: 30px;
  }
}

.image-wrapper {
  position: relative;
  overflow: hidden;
  padding-top: calc(100% / (16 / 9));
  width: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.case-page {
  @extend %case-page;
}

%typography-inner {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 5px;

  @include media-breakpoint-up(tablet) {
    font-weight: 500;
  }
}

ul,
ol {
  %case-page & {
    @extend %typography-inner;
    padding-left: 55px;
  }
}

p,
span {
  %case-page & {
    @extend %typography-inner;
  }
}

a {
  %case-page & {
    @extend %typography-inner;
    color: $secondary;
    text-decoration: none;
  }
}
