.recommended {
  overflow: hidden;

  &__main {
    margin: 0 0 40px;
  }

  &__footer {
    gap: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .swiper-button {
    &.swiper-button-lock {
      display: none;
    }
  }
}

